<template>
   <div class="pa-0" style="width:100%;height:100%;">
      <v-img v-if="staticImg"
         :src="staticImg" contain
         class="mediaContainer"
         />
      <canvas v-else
         ref="cam-canvas"
         class="mediaContainer">
      </canvas>
   </div>
</template>

<script>
export default {
   props: ['streamUrl', 'staticImg'],
   data () {
      return {
         player: null
      }
   },
   methods: {
      initCamStream () {
         if(!this.streamUrl) return
            // this.stopAllCam()
            // eslint-disable-next-line
            this.player = new JSMpeg.Player(this.streamUrl, {
            disableWebAssembly: true,
            canvas: this.$refs["cam-canvas"]
         })
      },
      stopCam  () {
         try {
            this.player.destroy()
         } catch(ex){
            console.log(ex)
         }
      }
   },
   created () {
      let self = this
      setTimeout(function () {
         self.initCamStream()
      }, 1000)
   },
   destroyed () {
      this.stopCam()
   }
}
</script>

<style lang="scss">
   .mediaContainer {
      width:100%;
      height:100%;
      background-color:black;
   }
</style>